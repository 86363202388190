<template>
  <div class="pa-4">
    <SupplierInternalPartProduction
      :part="part"
      :supplier-u-u-i-d="$route.params.supplierUUID"
      :supplier-part-u-u-i-d="$route.params.supplierPartUUID"
    ></SupplierInternalPartProduction>
  </div>
</template>

<script>
import {
  ApiErrorParser,
} from '@cloudmanufacturingtechnologies/portal-components';

import SupplierInternalPartProduction from '../../../components/supplierInternalPartProduction/SupplierInternalPartProduction';

const i18nData = require('./pageInternalWarehousePartProduction.i18n');

export default {
  name: 'PageInternalWarehousePartProduction',
  components: {
    SupplierInternalPartProduction,
  },
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      part: {},
    };
  },
  created() {
    this.getSupplierInternalPart();
  },
  methods: {
    getSupplierInternalPart() {
      this.part = {};
      this.$apiInstance
        .getSupplierInternalPart(
          this.$route.params.supplierUUID,
          this.$route.params.supplierPartUUID
        )
        .then((data) => {
          // We must set attributes to null otherwise Vue doesn't detect changes and thus doesn't update components
          if (!data.material) {
            data.material = null;
          }
          if (!data.technology) {
            data.technology = null;
          }
          this.part = data;
          if (this.part.analysisStatus === 'IN_PROGRESS' || this.part.analysisStatus === 'WAITING') {
            setTimeout(() => {
              this.checkSupplierInternalPartAnalysisStatus(2000);
            }, 2000);
          }
        })
        .catch((error) => {
          this.$notification.notify('DANGER', this.$t(ApiErrorParser.parse(error)));
        });
    },
    checkSupplierInternalPartAnalysisStatus(nextTimeout) {
      this.$apiInstance
        .getSupplierInternalPart(
          this.$route.params.supplierUUID,
          this.$route.params.supplierPartUUID
        )
        .then((data) => {
          this.part.analysisStatus = data.analysisStatus;
          if (this.part.analysisStatus === 'IN_PROGRESS' || this.part.analysisStatus === 'WAITING') {
            setTimeout(() => {
              this.checkSupplierInternalPartAnalysisStatus(
                Math.min(16000, nextTimeout * 2)
              );
            }, nextTimeout);
          }
        })
        .catch((error) => {
          this.$notification.notify('DANGER', this.$t(ApiErrorParser.parse(error)));
        });
    },
  },
};
</script>
